import { AllocationSetting, AllocationSettingExportTypes } from './../../../../shared/model/allocationSetting';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Logger } from 'fsts';
import { namespace } from 'vuex-class';
import { AllocationFrame } from '@/shared/model/allocationFrame';
import { OdataItems } from '@/shared/model/OdataItems';
import EditAllocationFrameDialog from './_edit-allocation-frame-dialog/edit-allocation-frame-dialog.vue';
import EditSplitBookingAccountDialog from './_edit-split-booking-account-dialog/edit-split-booking-account-dialog.vue';
import { Organisation } from '@/shared/model/organisation';
import organisationDocumentSetting, { OrganisationDocumentSetting } from '@/shared/model/organisationDocumentSetting';
import datevSetting, { DatevSetting } from '@/shared/model/datevSetting';
import { Route } from 'vue-router';
import store from '@/shared/store';

const name = 'account-assignment-settings-view';
const logger = new Logger(name);
const allocationFrameModule = namespace('allocationFrame');
const allocationSettingModule = namespace('allocationSetting');
const organisationModule = namespace('organization');
const organisationDocumentSettingModule = namespace('organisationDocumentSetting');
const datevSettingModule = namespace('datevSetting');

@Component({ name: name, components: { EditAllocationFrameDialog, EditSplitBookingAccountDialog } })
export default class AccountAssignmentSettingsView extends Vue {
  @datevSettingModule.Getter('getDatevSetting')
  private datevSetting!: DatevSetting;
  @datevSettingModule.Action('getDatevSetting')
  private getDatevSetting!: any;
  @datevSettingModule.Action('updateDatevSetting')
  private updateDatevSetting!: any;
  @datevSettingModule.Getter('getIsUpdating')
  private datevSettingIsUpdating!: boolean;
  @allocationSettingModule.Getter('getAllocationSetting')
  private allocationSetting!: AllocationSetting;
  @allocationSettingModule.Action('setExportType')
  private allocationSettingSetExportType!: (selected: string) => {};

  @organisationDocumentSettingModule.Action('getOrganisationDocumentSetting')
  private getOrganisationDocumentSetting!: any;
  @organisationDocumentSettingModule.Action('updateOrganisationDocumentSetting')
  private updateOrganisationDocumentSetting!: any;
  @organisationDocumentSettingModule.Getter('getIsUpdating')
  private organisationDocumentIsUpdating!: boolean;
  @organisationDocumentSettingModule.Getter('getOrganisationDocumentSetting')
  private organisationDocumentSetting!: OrganisationDocumentSetting;
  @organisationModule.Getter('getOrganization')
  private organization!: Organisation;

  @allocationFrameModule.Action('getAllocationFrames')
  private getAllocationFrames!: any;
  @allocationFrameModule.Getter('getAllocationFrames')
  private allocationFrames!: OdataItems<AllocationFrame>;

  get allocationFrame() {
    const all = this.allocationFrames.items.filter((x) => x.id == this.organization.allocationFrameId);
    return all.length > 0 ? all[0] : { name: '___' };
  }
  //#region Dialog logic
  @organisationModule.Action('updateOrganization')
  private updateOrganization!: any;
  dialogAddEdit = {
    show: false,
    model: {},
  };

  async dialogAddEditOnClose(folder: any) {
    this.dialogAddEdit.show = false;
  }

  async dialogAddEditOnUpdate(item: any) {
    this.updateOrganization(item);
    this.dialogAddEdit.show = false;
  }

  private async openDialog(status: any) {
    this.dialogAddEdit.model = { ...status };
    this.dialogAddEdit.show = true;
  }
  //#endregion
  //#region Dialog logic
  dialogEditSplitBookingAccount = {
    show: false,
    model: {},
  };

  async dialogEditSplitBookingAccountOnClose(folder: any) {
    this.dialogEditSplitBookingAccount.show = false;
  }

  async dialogEditSplitBookingAccountOnUpdate(item: any) {
    this.updateOrganization(item);
    this.dialogEditSplitBookingAccount.show = false;
  }

  private async openDialogEditSplitBookingAccount(status: any) {
    this.dialogEditSplitBookingAccount.model = { ...status };
    this.dialogEditSplitBookingAccount.show = true;
  }
  //#endregion

  private onClickSplittbuchungskonto() {
    // table OrganisationDocumentSetting; edit splitBookingAccount
    this.openDialogEditSplitBookingAccount(this.organization);
  }
  private onClickAllocationFrameID() {
    // table OrganisationDocumentSetting; edit allocationFrameID
    this.openDialog(this.organization);
  }
  private onChangeAllocationsLockMonth() {
    this.updateOrganization(this.organization);
  }
  private onChangeAllocationsLockYear() {
    this.updateOrganization(this.organization);
  }
  get allocationsExportTypes() {
    return [
      {
        value: AllocationSettingExportTypes.custom,
        text: this.$t(`allocations_settings_${AllocationSettingExportTypes.custom}`).toString(),
      },
      {
        value: AllocationSettingExportTypes.datev,
        text: this.$t(`allocations_settings_${AllocationSettingExportTypes.datev}`).toString(),
      },
      {
        value: AllocationSettingExportTypes.loco_soft,
        text: this.$t(`allocations_settings_${AllocationSettingExportTypes.loco_soft}`).toString(),
      },
    ];
  }
  onChangeAllocationSettingExportType(selected: any) {
    this.allocationSettingSetExportType(selected);
  }
  get allocationsLockMonths() {
    return [
      { value: null, text: this.$t('allocations_lock_months.no_lock') },
      { value: 1, text: this.$t('allocations_lock_months.january') },
      { value: 2, text: this.$t('allocations_lock_months.february') },
      { value: 3, text: this.$t('allocations_lock_months.march') },
      { value: 4, text: this.$t('allocations_lock_months.april') },
      { value: 5, text: this.$t('allocations_lock_months.may') },
      { value: 6, text: this.$t('allocations_lock_months.june') },
      { value: 7, text: this.$t('allocations_lock_months.july') },
      { value: 8, text: this.$t('allocations_lock_months.august') },
      { value: 9, text: this.$t('allocations_lock_months.september') },
      { value: 10, text: this.$t('allocations_lock_months.october') },
      { value: 11, text: this.$t('allocations_lock_months.november') },
      { value: 12, text: this.$t('allocations_lock_months.december') },
    ];
  }
  get allocationsLockYears() {
    return [
      { value: null, text: this.$t('allocations_lock_years.no_lock') },
      { value: -1, text: this.$t('allocations_lock_years.last_year') },
      { value: 0, text: this.$t('allocations_lock_years.current_yea') },
      { value: 1, text: this.$t('allocations_lock_years.next_year') },
    ];
  }
  public async onDetectDoubleBookingsChange(newVal: OrganisationDocumentSetting, oldVal: OrganisationDocumentSetting) {
    this.updateOrganisationDocumentSetting(this.organisationDocumentSetting);
  }
  public async onDatevSettingChange(newVal: boolean, oldVal: boolean) {
    this.updateDatevSetting(this.datevSetting);
  }

  //#region Handle routing
  async beforeRouteEnter(to: Route, from: Route, next: any) {
    logger.log('beforeRouteEnter');
    await AccountAssignmentSettingsView.loadFormData();
    next(async (vm: any) => {});
  }
  private static async loadFormData() {
    await Promise.all([
      store.dispatch('allocationFrame/getAllocationFrames'),
      store.dispatch('organisationDocumentSetting/getOrganisationDocumentSetting'),
      store.dispatch('datevSetting/getDatevSetting'),
    ]);
  }
  //#endregion
  async mounted() {}
}
