import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Logger } from 'fsts';
import DateUtils from '@/shared/utils/DateUtils';
import { OdataItems } from '@/shared/model/OdataItems';
import { AllocationFrame } from '@/shared/model/allocationFrame';
import { namespace } from 'vuex-class';

const name = 'edit-split-booking-account-dialog';
const logger = new Logger(name);

@Component({
  name: name,
  components: {},
})
export default class EditSplitBookingAccountDialog extends Vue {
  @Prop({ default: false })
  private dialog!: boolean;

  @Prop({ default: () => {} })
  private value!: any;

  private onClose() {
    this.$emit('click:close', this.value);
  }

  private async onConfirm() {
    this.$emit('click:update', this.value);
  }
}
