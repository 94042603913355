import { render, staticRenderFns } from "./account-assignment.html?vue&type=template&id=1d704f76&scoped=true&external"
import script from "./account-assignment.ts?vue&type=script&lang=ts&external"
export * from "./account-assignment.ts?vue&type=script&lang=ts&external"
import style0 from "./account-assignment.scss?vue&type=style&index=0&id=1d704f76&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1d704f76",
  null
  
)

/* custom blocks */
import block0 from "./account-assignment.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fusr%2Fsrc%2Fapp%2Fsrc%2Fviews%2Fsettings%2Fcompany%2Faccount-assignment%2Faccount-assignment.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VHover } from 'vuetify/lib/components/VHover';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
installComponents(component, {VCard,VCol,VHover,VProgressCircular,VRow,VSelect,VSubheader,VSwitch,VTab,VTabs})
